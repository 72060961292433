<template>
  <div id="page-user-view">
    <div v-if="showActivities">
      <vx-card>
        <div class="space-between">
        <h2>{{ child.fullName }}'s Learning Stories</h2>
        <div class="flex items-center">
          <div class="datepicker-wrapper hasIcon m-5">
            <label class="block mb-2"> From</label>
            <datepicker
              :minimumView="'month'"
              :maximumView="'month'"
              icon-after="true"
              icon="calendar_today"
              icon-pack="feather"
              class="w-full"
              :format="dateFormat"
              v-model="startDate"
              placeholder="Select Start Month"
              :value="startDate"
            ></datepicker>
            <vs-icon icon="calendar_today" class="icon mt-3"></vs-icon>
          </div>
          <div class="datepicker-wrapper hasIcon m-5">
            <label class="block mb-2"> To</label>
            <datepicker
              :minimumView="'month'"
              :maximumView="'month'"
              icon-after="true"
              icon="calendar_today"
              icon-pack="feather"
              :format="dateFormat"
              v-model="endDate"
              placeholder="Select End Month"
              :value="endDate"
              :disabled-dates="disabledDates"
            ></datepicker>
            <vs-icon icon="calendar_today" class="icon mt-3"></vs-icon>
          </div>
          <vs-button
            color="primary"
            class="mt-6"
            @click="handleAllPrintActivity()"
            v-if="activities.length>0"
            >Print All
          </vs-button>
        </div>
      </div>
        <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
          <div
            v-for="activity in activities"
            :key="activity._id"
            class="ph-img-list align-button"
          >
            <a @click="activitySelect(activity._id)">
              <img
                :src="
                  activity.photos.length
                    ? activity.photos[0].photo
                    : $defaultImage
                "
                class="responsive h-64 object-cover"
              />
            </a>
            <span class="mt-5 block" @click="activitySelect(activity._id)" style="margin-bottom:10px">{{
              activity.name
            }}</span>
              <vs-button
                color="primary"
                class="mb-2"
                style="margin-top:auto"
                @click="handleSinglePrintActivity(activity._id)"
                >Print
              </vs-button>
          </div>
        </div>
        <div
          class="pagination-div flex justify-center float-none mt-5"
          v-if="totalPages"
        >
          <paginate
            :page-count="totalPages"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
          ></paginate>
        </div>
      </vx-card>
    </div>
    <div v-if="showActivityDetail" id="page-print" >
      <vx-card class="page-margins">
        <div class="vx-breadcrumb mb-5 md:block hidden-print">
          <ul class="flex flex-wrap items-center">
            <li class="inline-flex items-end">
              <a class="" @click="goBack"> Learning stories</a>
              <span class="breadcrumb-separator mx-2">
                <span class="feather-icon select-none relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-chevrons-right w-4 h-4"
                  >
                    <polyline points="13 17 18 12 13 7"></polyline>
                    <polyline points="6 17 11 12 6 7"></polyline>
                  </svg>
                </span>
              </span>
            </li>
            <li class="inline-flex items-center">
              <a class="">
                {{
                  moment(selectedActivity.updatedAt) +
                  " - " +
                  selectedActivity.name
                }}</a
              >
            </li>
          </ul>
        </div>
        <div class="flex items-center mb-5">
          <h2 class="mb-0 header-text">{{ selectedActivity.name }}</h2>
        </div>
        <p class="full-width-detail">{{ selectedActivity.description }}</p>
        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center skill-div">
              <strong>Core Skill</strong>
              <img
                :src="
                  selectedActivity.coreSkills.icon
                    ? selectedActivity.coreSkills.icon
                    : $defaultImage
                "
                :alt="selectedActivity.coreSkills.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.coreSkills.name }}
            </li>
            <li class="px-5 md:px-10 text-center skill-div">
              <strong>Ways of Learning</strong>
              <img
                :src="
                  selectedActivity.waysOfLearnings.icon
                    ? selectedActivity.waysOfLearnings.icon
                    : $defaultImage
                "
                :alt="selectedActivity.waysOfLearnings.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.waysOfLearnings.name }}
            </li>
            <li class="pl-5 md:pl-10 text-center skill-div">
              <strong>STREAM</strong>
              <img
                :src="
                  selectedActivity.streams.icon
                    ? selectedActivity.streams.icon
                    : $defaultImage
                "
                :alt="selectedActivity.streams.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.streams.name }}
            </li>
          </ul>
        </div>
        <div class="mt-20">
          <h4 class="font-bold">Play with Purpose. (Your Intentionality)</h4>
          {{ selectedActivity.purposeStatement }}
        </div>
        <div class="mt-20">
          <h4 class="font-bold">How has {{ child.fullName }} changed? (Assessment of Learning)</h4>
          {{ selectedActivity.children.howHasChanged }}
        </div>
        <div class="hidden-web">
            <div class="flex flex-wrap">
              <div v-for="(photo,index) in media" :key="photo._id" class="story-image-container">
                <a href="javascript::void(0)" @click.prevent="openGallery(index)">
                  <img :src="photo.src" class="story-image" />
                </a>
              </div>
            </div>
          </div>
          <div class="hidden-print">
            <div class="flex flex-wrap ph-img-list-wrap ph-5-col">
              <div v-for="(photo,index) in media" :key="photo._id" class="ph-img-list">
                <a href="javascript::void(0)" @click.prevent="openGallery(index)">
                  <img :src="photo.src" class="story-image" />
                </a>
              </div>
            </div>
          </div>
        <div class="mt-10">
          <h4 class="font-bold">Child's Voice</h4>
          {{ selectedActivity.children.childVoice }}
        </div>
        <div class="mt-10">
          <h4 class="font-bold">Core skill reflection</h4>
          {{ selectedActivity.children.coreSkillReflection }}
        </div>
      </vx-card>
      <vx-card style="margin-top: 30px" v-if="selectedActivity.followUp" class="page-margins">
        <h4 class="font-bold">
          FOLLOW UP THE LEARNING: {{ selectedActivity.followUp.name }}
        </h4>
        <p>{{ selectedActivity.followUp.description }}</p>

        <div class="w-full mt-10">
          <ul class="flex">
            <li class="pr-5 md:pr-10 text-center skill-div">
              <strong>Core Skill</strong>
              <img
                :src="
                  selectedActivity.followUp.coreSkills.icon
                    ? selectedActivity.followUp.coreSkills.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.coreSkills.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.followUp.coreSkills.name }}
            </li>
            <li class="px-5 md:px-10 text-center block skill-div">
              <strong>Ways of Learning</strong>
              <img
                :src="
                  selectedActivity.followUp.waysOfLearnings.icon
                    ? selectedActivity.followUp.waysOfLearnings.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.waysOfLearnings.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.followUp.waysOfLearnings.name }}
            </li>
            <li class="pl-5 md:pl-10 text-center skill-div">
              <strong>STREAM</strong>
              <img
                :src="
                  selectedActivity.followUp.streams.icon
                    ? selectedActivity.followUp.streams.icon
                    : $defaultImage
                "
                :alt="selectedActivity.followUp.streams.name"
                class="h-16 object-contain block mx-auto mt-5 skill-image"
              />
              {{ selectedActivity.followUp.streams.name }}
            </li>
          </ul>
        </div>
        <div class="mt-10 mb-5">
          <h4 class="font-bold">Teacher's Comment</h4>
          {{ selectedActivity.followUp.followUpCompletedText }}
        </div>
      </vx-card>
      <vx-card class="mt-10 page-margins page-print-class">
        <h2 class="header-text">
          Early Years Learning Framework: Where can this play with purpose take
          {{ child.fullName }}
        </h2>
        <vs-row>
          <vs-col
            v-for="(category, key) in categories"
            :key="key"
            vs-lg="6"
            vs-sm="12"
            vs-xs="12"
            class="eylf-col px-0 md:pr-10 border-b-0 mb-5 md:mb-10 pl-0 ml-0"
          >
            <h4 class="font-bold mt-10">
              Learning Framework {{ category.title }}
            </h4>
            <ul class="hidden-web">
              <li
                v-for="(outcome, index) in category.eylfChecklists.filter(property => isEylfExist(property._id))"
                :key="index + 0.2"
                class="flex items-center mb-3"
              >
                <img
                  :src="
                    isEylfExist(outcome._id)
                      ? $defaultIconTick
                      : $defaultIconMinus
                  "
                  class="mr-5 ml-0"
                />
                <strong class="mr-3"
                  >{{ category.title | singularize }} {{ index + 1 }}:
                </strong>
                <p class="framework-title">{{ outcome.title }}</p>
                <vx-tooltip
                  :text="outcome.description"
                  color="#000000"
                  position="right"
                  class="inline-block hidden-print"
                >
                  <a href="#" class="ml-3">
                    <feather-icon icon="InfoIcon" style="width: 15px" />
                  </a>
                </vx-tooltip>
              </li>
            </ul>
            <ul class="hidden-print">
              <li
                v-for="(outcome, index) in category.eylfChecklists"
                :key="index + 0.2"
                class="flex items-center mb-3"
              >
                <img
                  :src="
                    isEylfExist(outcome._id)
                      ? $defaultIconTick
                      : $defaultIconMinus
                  "
                  class="mr-5 ml-0"
                />
                <strong class="mr-3"
                  >{{ category.title | singularize }} {{ index + 1 }}:
                </strong>
                {{ outcome.title }}
                <vx-tooltip
                  :text="outcome.description"
                  color="#000000"
                  position="right"
                  class="inline-block hidden-print"
                >
                  <a href="#" class="ml-3">
                    <feather-icon icon="InfoIcon" style="width: 15px" />
                  </a>
                </vx-tooltip>
              </li>
            </ul>
          </vs-col>
        </vs-row>
      </vx-card>
      <LightBox
        ref="lightbox"
        :media="media"
        :show-light-box="false"
        :show-caption="false"
        :show-thumbs="false"
        @onImageChanged="onImageChanged"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Vue from "vue";
import VueLodash from "vue-lodash";
import _ from "lodash";
import LightBox from "vue-image-lightbox";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import Datepicker from "vuejs-datepicker";
require("../../../../assets/scss/datepicker.scss");

Vue.use(VueLodash, { name: "custom", lodash: _ });
export default {
  components: { LightBox, Datepicker },
  data() {
    return {
      itemPerPage: 15,
      page: 1,
      totalPages: 0,
      activities: [],
      showActivities: true,
      showActivityDetail: false,
      currentImageIndex:0,
      selectedActivity: "",
      categories: [],
      media: [],
      startDate: "",
      endDate: new Date(),
      dateFormat: "MMMM",
      disabledDates: {}
    };
  },
  props: {
    child: "",
  },
  methods: {
    ...mapActions("activity", [
      "fetchCompletedActivityByChildId",
      "fetchActivityByActivityIdAndChildId",
    ]),
    ...mapActions("eylfCategory", ["fetchEYLFCategoryWithFrameworkByCenterId"]),
    goBack() {
      this.showActivities = true;
      this.showActivityDetail = false;
    },
    openGallery(index) {
        this.$refs.lightbox.showImage(index);
        this.currentImageIndex = index;
      },
      onImageChanged(index) {
        this.currentImageIndex = index;
      },
    moment(date) {
      return moment(date).format("DD/MM/YY");
    },
    isEylfExist(key) {
      return _.includes(this.selectedActivity.eylfFrameworks, key);
    },
    async getActivities(childId, page) {
      this.$vs.loading();
      this.activities = [];
      let startDate;
      let endDate;
      if (this.startDate !== "") {
        startDate =
          this.startDate.getFullYear() +
          "-" +
          parseInt(this.startDate.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-01";
        let lastDay = new Date(
          this.endDate.getFullYear(),
          this.endDate.getMonth() + 1,
          0
        );
        endDate =
          lastDay.getFullYear() +
          "-" +
          parseInt(lastDay.getMonth() + 1)
            .toString()
            .padStart(2, 0) +
          "-" +
          lastDay.getDate();

        const data = {
          childId: this.childId,
          page: this.page,
          limit: this.itemPerPage,
          startDate,
          endDate
        };
        await this.fetchCompletedActivityByChildId(data).then((response) => {
          this.$vs.loading.close();
          if (response.data.data.docs.length) {
            this.activities = response.data.data.docs;
            this.totalPages = Math.ceil(
              response.data.data.pagination.total / this.itemPerPage
            );
          }
        });
      }
      this.$vs.loading.close();
    },
    handleChangePage(page) {
      this.getActivities(this.childId, page);
    },
    async activitySelect(activityId) {
      const data = {
        activityId: activityId,
        childId: this.childId,
      };
      this.showActivities = false;

      await this.fetchActivityByActivityIdAndChildId(data).then(async (response) => {
        this.selectedActivity = response.data.data;
        this.media = response.data.data.photos.map((item) => {
          if (item.taggedTo.includes(this.childId)) {
            return {
              thumb: item.photo,
              src: item.photo,
              srcset: item.photo
            };
          }
        }).filter((item) => item !== undefined);
        this.showActivityDetail = true;
      });
    },
    async handleSinglePrintActivity(activityId) {
      let childId = this.childId;

      let prtAllContent = "";
          let data = {
            activityId,
            childId,
          };
      await this.fetchActivityByActivityIdAndChildId(data)
        .then(async (response) => {
          this.showActivityDetail = true;
          this.selectedActivity = response.data.data;
          this.media = response.data.data.photos.map((item) => {
            if (item.taggedTo.includes(this.childId)) {
              return {
                thumb: item.photo,
                src: item.photo,
                srcset: item.photo
              };
            }
          }).filter((item) => item !== undefined);
        })
        .then(() => {
          prtAllContent = document.getElementById("page-print").innerHTML;
        })
        .then(() => {
          this.showActivityDetail = false;
        });
      let popupWin = window.open(
        "",
        "_blank",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      popupWin.document.open();
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML;
      }
      stylesHtml +=
        '<link rel="stylesheet" type="text/css" href="/print.css" />';

      popupWin.document.write(
        `<html>
          <head>
            <title>::Preview::</title>
            <style>
                      .date-div {
                        display: block !important;
                      }

            </style>` +
        stylesHtml +
        '</head><body onload="window.print()">'
      );
      popupWin.document.write(prtAllContent);
      popupWin.document.write("</html>");
      popupWin.document.close();

    },

    async handleAllPrintActivity() {
      let childId = this.childId;
      let prtSingleContent;
      let prtAllContent = "";
      for (const element of this.activities) {
        let activityId = element._id;
          let data = {
          activityId: activityId,
          childId,
        };
        await this.fetchActivityByActivityIdAndChildId(data)
          .then(async (response) => {
            this.showActivityDetail = true;
            this.selectedActivity = response.data.data;
            this.media = response.data.data.photos.map((item) => {
              if (item.taggedTo.includes(this.childId)) {
                return {
                  thumb: item.photo,
                  src: item.photo,
                  srcset: item.photo
                };
              }
            }).filter((item) => item !== undefined);
          })
          .then(() => {
            prtSingleContent = document.getElementById("page-print").innerHTML;
            prtSingleContent = prtSingleContent + "<div style='break-after:always'></div>";
          })
          .then(() => {
            prtAllContent = prtAllContent + prtSingleContent;
            this.showActivityDetail = false;
          });
      }
      let popupWin = window.open(
        "",
        "_blank",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      popupWin.document.open();
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style')
      ]) {
        stylesHtml += node.outerHTML;
      }
      stylesHtml +=
        '<link rel="stylesheet" type="text/css" href="/print.css" />';

      popupWin.document.write(
        `<html>
          <head>
            <title>::Preview::</title>
            <style>
              .date-div {
                display: block !important;
              }
              .page-print-class {
                page-break-after: always;
                page-break-inside: avoid;
              }
            </style>` +
        stylesHtml +
        '</head><body onload="window.print()">'
      );
      popupWin.document.write(prtAllContent);
      popupWin.document.write("</html>");
      popupWin.document.close();

    },
    async getEylfChecklist(centerId) {
      await this.fetchEYLFCategoryWithFrameworkByCenterId(centerId).then(
        (checklist) => {
          this.categories = checklist.data.data.eylfCategories;
        }
      );
    },
  },
  mounted() {
    this.getActivities(this.childId, this.page);
    this.getEylfChecklist(this.child.learningCenterId);
    if (this.$route.query.start && this.$route.query.end) {
      this.startDate = new Date(this.$route.query.start);
      this.endDate = new Date(this.$route.query.end);
    }else{
      let today = new Date(),
      lastThreeMonths = new Date();
      lastThreeMonths.setMonth(lastThreeMonths.getMonth() - 3);
      this.startDate = lastThreeMonths;
    }
  },
  computed: {
    childId() {
      return this.$route.params.childId;
    },
  },
  watch: {
    startDate(obj) {
      this.disabledDates = {to:this.startDate}
      if ("" != this.startDate && "" != this.endDate) {
         if (!this.initialLoad) {
            this.getActivities();
         }

        this.timePeriod = this.monthDiff + " months";
      }
    },
    endDate(obj) {
      this.disabledDates = {to:this.startDate}
      if ("" != this.startDate && "" != this.endDate) {
         if (!this.initialLoad) {
            this.getActivities();
         }
        this.timePeriod = this.monthDiff + " months";
      }
    }
  },
  filters: {
    singularize: function (value) {
      return value[value.length - 1] === "s" ? value.slice(0, -1) : value;
    },
  },
};
</script>
<style lang="scss">
.vs-tooltip {
  z-index: 100000;
}

.space-between {
  display: flex;
  justify-content: space-between
}

.margin-auto{
  margin-top: auto;
  // height: 10px;
}

.vs-tooltip {
  color: #222222;
  font-size: 15px;
  line-height: 20px;
  padding: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
  min-width: 300px !important;
}

.align-button {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.hidden-web {
  display: none
}

</style>